import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { FooterNavigation, SocialLinks } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useContext, useContextSelector } from 'use-context-selector';
import { GlobalContentContext } from '@/components/globalContentContext/GlobalContentContext';
import { LanguageSwitcher } from '@/components/languageSwitcher/LanguageSwitcher';
import { MenuContext } from '@/components/menuContext/MenuContext';
import { NewsletterSignupConnected } from '@/components/newsletterSignup/NewsletterSignupConnected';
import FooterLogoIcon from '@/icons/misc/footer-logo.svg';
import LevelAccessIcon from '@/icons/misc/level-access.svg';

import { storeLocale } from '@/root/constants';
import { FeatureFlagsContext } from '../featureFlagsProvider/FeatureFlagsContext';
import styles from './SiteFooter.module.scss';

declare global {
  interface Window {
    OneTrust?: {
      ToggleInfoDisplay: () => void;
    };
  }
}

/**
 * Site Footer.
 */
export const SiteFooter = () => {
  const router = useRouter();
  const { locale: routerLocale } = router;
  const locale = storeLocale(routerLocale);

  const footerMenu =
    useContextSelector(MenuContext, ({ menus }) => menus?.footer) || [];

  const { socialLinks = [] } = useContext(GlobalContentContext);
  const currentYear = new Date().getFullYear();

  const showCookiesSettings = () => {
    window?.OneTrust?.ToggleInfoDisplay();
  };

  const featureFlagsContext = useContext(FeatureFlagsContext);
  const isFooterNewsletterEnabled =
    featureFlagsContext?.featureFlags?.[locale].isFooterNewsletterEnabled;

  return (
    <section className={clsx(styles.siteFooter)}>
      <div
        className={clsx(
          styles.siteFooter,
          styles.siteFooter__mobileInvisible,
          styles.siteFooter__desktop,
          'grid'
        )}
      >
        <div
          className={clsx(
            styles.siteFooter__footerContentWrapper,
            'col xs l1-12 grid'
          )}
        >
          <div
            className={clsx(styles.siteFooter__leftContainer, 'col xs l1-7')}
          >
            <div className={styles.siteFooter__navigationWrapper}>
              <FooterNavigation footerMenu={footerMenu} />
            </div>
            <div className={styles.siteFooter__languageSwitchWrapper}>
              <LanguageSwitcher />

              <div className={styles.siteFooter__cookieSettings}>
                <button
                  className={clsx(
                    styles.siteFooter__cookieSettingsButton,
                    'text-body-small'
                  )}
                  type="button"
                  onClick={showCookiesSettings}
                >
                  <Trans
                    id="footer.cookies.doNotSell"
                    message="Do not Share or Sell Personal Information"
                  />
                </button>
              </div>

              {locale !== 'es-ES' && (
                <p className={'text-body-small'}>
                  <Trans
                    id="footer.supplyChainAct"
                    message="California Transparency in Supply Chains Act"
                  />
                </p>
              )}
              <p className={'text-body-small'}>
                <Trans
                  id="footer.copyright"
                  message={`© Copyright ${currentYear} Wella Operations US LLC, all trademarks
                  registered. All rights reserved.`}
                  values={{ currentYear }}
                />
              </p>
            </div>
          </div>

          <div
            className={clsx(styles.siteFooter__rightContainer, 'col xs l10-12')}
          >
            {isFooterNewsletterEnabled ? (
              <div className={styles.siteFooter__newsletterWrapper}>
                <NewsletterSignupConnected {...{ deviceType: 'desktop' }} />
              </div>
            ) : null}
            <div>
              <div className={styles.siteFooter__levelAccessWrapper}>
                <a
                  aria-label={t({
                    id: 'product.accessibility',
                    message:
                      'This icon serves as a link to download the eSSENTIAL Accessibility assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion.',
                  })}
                  className={styles.siteFooter__levelAccessIcon}
                  href="https://www.levelaccess.com/opi/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <LevelAccessIcon />
                </a>
              </div>
              <div className={styles.siteFooter__socialLinksWrapper}>
                <SocialLinks links={socialLinks} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(styles.siteFooter__logoWrapper, 'col xs l1-12')}
          role="img"
          aria-label={i18n._(
            /* i18n */ {
              id: 'footer.logoMessage',
              message: 'OPI Logo',
            }
          )}
        >
          <FooterLogoIcon />
        </div>
      </div>

      <div className={clsx(styles.siteFooter, styles.siteFooter__mobile)}>
        <div className={clsx(styles.siteFooter__componentsContainer)}>
          <div className={styles.siteFooter__newsletterWrapper}>
            <NewsletterSignupConnected {...{ deviceType: 'mobile' }} />
          </div>

          <div className={styles.siteFooter__navigationWrapper}>
            <FooterNavigation footerMenu={footerMenu} />
          </div>

          <div>
            <div className={styles.siteFooter__levelAccessWrapper}>
              <a
                aria-label={t({
                  id: 'product.accessibility',
                  message:
                    'This icon serves as a link to download the eSSENTIAL Accessibility assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion.',
                })}
                className={styles.siteFooter__levelAccessIcon}
                href="https://www.levelaccess.com/opi/"
                rel="noreferrer"
                target="_blank"
              >
                <LevelAccessIcon />
              </a>
            </div>

            <div className={styles.siteFooter__socialLinksWrapper}>
              <SocialLinks links={socialLinks} />
            </div>
          </div>

          <div className={styles.siteFooter__languageSwitchWrapper}>
            <LanguageSwitcher />

            <div className={styles.siteFooter__cookieSettings}>
              <button
                className={clsx(
                  styles.siteFooter__cookieSettingsButton,
                  'text-body-small-mobile'
                )}
                type="button"
                onClick={showCookiesSettings}
              >
                <Trans
                  id="footer.cookies.doNotSell"
                  message="Do not Share or Sell Personal Information"
                />
              </button>
            </div>

            {locale !== 'es-ES' && (
              <p className={clsx('text-body-small-mobile')}>
                <Trans
                  id="footer.supplyChainAct"
                  message="California Transparency in Supply Chains Act"
                />
              </p>
            )}
            <p className={clsx('text-body-small-mobile')}>
              <Trans
                id="footer.copyright"
                message={`© Copyright ${currentYear} Wella Operations US LLC, all trademarks
                registered. All rights reserved.`}
                values={{ currentYear }}
              />
            </p>
          </div>

          <div
            className={clsx(styles.siteFooter__logoWrapper)}
            role="img"
            aria-label={i18n._(
              /* i18n */ {
                id: 'footer.logoMessage',
                message: 'OPI Logo',
              }
            )}
          >
            <FooterLogoIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteFooter;
